<template>
  <div>
    <!-- 模型加载loading -->
    <!-- <model-status v-if="modelLoading || modelInitializing" :modelLoading="modelLoading"
      :modelInitializing="modelInitializing"></model-status> -->
    <div class="back-ground-remover-wrap" :style="{ 'padding-top': getPadding }">
      <h1 class="tool-title" :style="{ 'margin-bottom': getMarginBottom }">
        Remove Portrait Background
        <!-- {{store.getters.getIsCollapse}} -->
      </h1>
      <!-- 上传组件 -->
      <div class="common-upload-box" ref="drag">
        <common-upload @changFileList="changFileList" @changSelectImage="changSelectImage" :imageUrls="imageUrls"
          :modelLoading="modelLoading" :modelInitializing="modelInitializing" :modelLoadingError="modelLoadingError"
          :webcamEnabled="webcamEnabled" :modelInProgress="modelInProgress" :imageHandleComplete="imageHandleComplete"
          :changeImage="changeImage" :percentSpeedTime="100" :uploadPlaceholder="uploadPlaceholder" />
      </div>

      <!-- 图片展示区域 -->
      <div class="backGroundRemover-container" :style="{
        opacity: imageHandleComplete && modelInProgress === 'done' ? 1 : 0,
        'margin-top': getMarginTop,
      }">
        <!-- 原图展示区域 -->
        <div class="origin-container container">
          <h3 class="result-title">Original</h3>
          <div class="image-box" style="width: 412px; height: 412px;">
            <image-board :srcData="rawImage" />
          </div>
        </div>

        <!-- 抠图展示区域 -->
        <div class="result-container container">
          <h3 class="result-title">Removed Background</h3>
          <div class="position-box" @mouseenter="handleMattingBoxMouseEnter" @mouseleave="handleMattingBoxMouseLeave">
            <div class="image-box result-image-box" style="width: 412px; height: 412px;">
              <image-board ref="resultBoardRef" :srcData="resultImage" :isModelDone="isModelDone"
                @drawDown="drawDown" />
            </div>
            <div class="buttons-container" v-if="showMattingEditBtns">
              <a-button type="default" @click="handleOpenMattingModal" class="ant-btn-default">Edit</a-button>
              <a-button type="primary" @click="handleMattingDownload">Download</a-button>
            </div>
          </div>
          <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate1" />
        </div>

        <!-- 背景替换区域 -->
        <div class="add-container container">
          <h3 class="result-title">Add Background</h3>
          <div class="image-box add-design-container" id="upload-container-draggle"
            :class="{ 'add-design-container-hover': isUploadBg }" style="width: 412px; height: 412px;"
            v-if="!showAddDesignResult" @drop="handleUploadBoxDrop">
            <div class="upload-box" id="upload-box-draggle" @mouseenter="handleUploadBg"
              @mouseleave="handleCancelUploadBg">
              <a-upload-dragger v-model="bgFileList" name="file" :customRequest="customBgUpload" id="bgUpload"
                @drop="handleFileDrop" :showUploadList="false" @change="handleReplaceBgChange" :accept="acceptFile"
                :beforeUpload="handleBeforeUpload">
                <p class="ant-upload-text" :class="{ 'ant-upload-text-color': isUploadBg }">{{
                    uploadBgPlaceholder ||
                    'Click or drop a background here'
                }} </p>
              </a-upload-dragger>
            </div>
            <div class="add-design-tip">
              <p>or</p>
              <p>try one of these</p>
            </div>
            <div class="bg-items">
              <img v-for="item in defaultBgImages" :key="item.id" :src="item.url" class="bg-item"
                @click="handleSelectBg(item)" />
            </div>
          </div>

          <div class="position-box" @mouseenter="handleReplaceBgBoxMouseEnter"
            @mouseleave="handleReplaceBgBoxMouseLeave">
            <div v-show="showAddDesignResult" class="image-box" style="width: 412px; height: 412px;">
              <image-board ref="bgBoardRef" :srcData="resultImage" :bgData="bgImage" :showOpacityBg="false" />
            </div>

            <div class="buttons-container" v-if="showEditReplaceBgBtns">
              <a-button @click="handleOpenReplaceBgModal" class="ant-btn-default">Edit</a-button>
              <a-button type="primary" @click="handleBgReplaceDownload">Download</a-button>
            </div>
          </div>
          <common-rate @changeRate="handleReplaceBgChangeRate" v-if="currentBgItem" :clearRate="clearRate" ref="rate" />
        </div>
      </div>
    </div>

    <!-- 擦除编辑弹窗 -->
    <matting-edit-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" />

    <!-- 替换背景弹窗 -->
    <bg-replace-modal :isShowReplaceBgModal="isShowReplaceBgModal" @closeModal="handleCloseReplaceBgModal"
      :resultImageData="resultImage" :currentBgItem="currentBgItem" @confirmReplaceBg="handleConfirmReplaceBg" />
  </div>
</template>

<script setup>
import loadImage from "blueimp-load-image";
import { runModelUtils } from "@/utils";
import { onMounted, ref, watch, defineProps, computed, watchEffect } from "vue";
import CommonUpload from "@/components/commonUpload.vue";
import MattingEditModal from "@/components/matting-edit-modal";
import BgReplaceModal from "@/components/bg-replace-modal";
import CommonRate from "@/components/common-rate.vue";
import dataUtils from "@/utils/util.js";
import { postCanvasToURL } from "@/utils/buriedFunction/getWebpFromCanvas";
import { uploadBackground } from '@/utils/buriedFunction/uploadBackground'
import ImageBoard from '@/components/image-board';
import { isString } from 'lodash';
import { bgsList } from './data.js'
import { message } from "ant-design-vue";
import { addDesignPreview } from '@/utils/buriedFunction/remover-background/add-design-preview'
import dncData from '@/utils/handleDncData.js'
import { renderCoverBgImageInCanvas } from '../../matting-board/helpers/drawing-helper';
import Worker from 'worker-loader!@/workers/worker'
import store from '../../../store'
import { stringify } from "querystring";
var md5 = require("md5");

const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  imageUrls: Array,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
});
const uploadPlaceholder = ref('Click or drop a portrait image here to start')
const uploadBgPlaceholder = ref('')
const inferenceTime = ref(null);
const session = ref(null);
const gpuSession = ref(null);
const cpuSession = ref(null);
const modelLoading = ref(true);
const modelInitializing = ref(true);
const sessionRunning = ref(false);
const imageURLInput = ref("");
const modelInProgress = ref('');
const imageHandleComplete = ref(false);
const imageLoadingError = ref(false);
const modelLoadingError = ref(false);
const webcamEnabled = ref(false);
const sessionBackend = ref("webgl");
const modelFile = ref(new ArrayBuffer(0));
const currentFileList = ref([]);
const bgFileList = ref([]);
const showAddDesignResult = ref(false);
const showMattingEditBtns = ref(false);
const changeImage = ref(false);
const rate = ref(null)
const rate1 = ref(null)
const rawImage = ref(null);
const resultImage = ref(null);
const bgImage = ref(null);
const resultBoardRef = ref(null);
const bgBoardRef = ref(null);

const isShowMattingModal = ref(false);
const isShowReplaceBgModal = ref(false);
const showEditReplaceBgBtns = ref(false);
const currentMattingSelectRate = ref({});
const currentReplaceBgSelectRate = ref({});
const clientHeight = ref(0);
const drag = ref(null);
const currentBgItem = ref(null);
const clearRate = ref(false)
const modalData = ref(null)
const isModelDone = ref(false)
const acceptFile = ref(null)
const uploadError = ref(false);
const isUploadBg = ref(false)
const proxyUrl = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API;
const defaultBgImages = ref([
  {
    id: 4,
    url: '/ai_tools/backgrounds/3VuFEc65rCemIaqll97ISd.webp'
  }, {
    id: 5,
    url: '/ai_tools/backgrounds/5DpjreDULpthMF0Dd5aRq6.webp'
  }, {
    id: 6,
    url: '/ai_tools/backgrounds/5uPVGaOp0ehIggt8w2z7Fe.webp'
  }
]);

// 背景图预加载
const preLoadImageList = () => {
  bgsList.forEach(item => {
    let img = new Image()
    img.src = item.url
    img.onload = () => {
      console.log('背景图预加载')
    }
  })
}

const getClientHeight = () => {
  const height = document.documentElement.clientHeight;
  clientHeight.value = height;
};

const getPadding = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "18px";
  } else if (imageHandleComplete.value && clientHeight.value > 789 && clientHeight.value <= 900) {
    return "48px";
  } else if (imageHandleComplete.value && clientHeight.value > 900) {
    return "80px";
  } else {
    return "180px";
  }
});

const handleBeforeUpload = (file) => {
  console.log(file)
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
    return
  }
};

const getMarginBottom = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "30px";
  } else {
    return "82px";
  }
});

const getMarginTop = computed(() => {
  if (imageHandleComplete.value && clientHeight.value <= 789) {
    return "30px";
  } else if (imageHandleComplete.value && clientHeight.value > 789) {
    return "82px";
  } else {
    return "-200px";
  }
});

// 监听窗口变化，调整间距
window.onresize = () => {
  getClientHeight();
};

// 模型初始化
const initSession = async () => {

  console.log('initSession=== 初始化')
  sessionRunning.value = false;
  modelLoadingError.value = false;
  if (sessionBackend.value === "webgl") {
    if (gpuSession.value) {
      session.value = gpuSession.value;
      return;
    }
    // modelLoading.value = true;
    // modelInitializing.value = true;
  }
  if (sessionBackend.value === "wasm") {
    if (cpuSession.value) {
      session.value = cpuSession.value;
      return;
    }
    // modelLoading.value = true;
    // modelInitializing.value = true;
  }
  try {
    if (sessionBackend.value === "webgl") {
      gpuSession.value = await runModelUtils.createModelGpu(modelFile.value);
      session.value = gpuSession.value;
    } else if (sessionBackend.value === "wasm") {
      cpuSession.value = await runModelUtils.createModelCpu(modelFile.value);
      session.value = cpuSession.value;
    }
  } catch (e) {
    modelLoading.value = false;
    modelInitializing.value = false;
    if (sessionBackend.value === "webgl") {
      gpuSession.value = undefined;
    } else {
      cpuSession.value = undefined;
    }
    throw new Error("Error: Backend not supported. ");
  }


  modelLoading.value = false;
  if (sessionBackend.value === "webgl") {
    setTimeout(() => {
      props.warmupModel(session.value);
      modelInitializing.value = false;
    }, 0);

  } else {
    console.log('warmupModel=== pre')
    await props.warmupModel(session.value);
    modelInitializing.value = false;
  }
};

onMounted(async () => {

  //判断当前设备类型
  const commonUploadDom = document.getElementById("bgUpload")
  commonUploadDom.removeAttribute("capture")
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    acceptFile.value = "image/*"
  } else {
    acceptFile.value = ".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG"
  }



  // 加载模型，并初始化
  console.log('props.modelFilepath=== props.modelFilepath')
  const response = await fetch(props.modelFilepath);
  console.log('response', response, JSON.stringify(response))
  const arrayBuffer = await response.arrayBuffer()
  console.log('arrayBuffer', arrayBuffer, JSON.stringify(arrayBuffer))
  modelFile.value = await dncData(arrayBuffer)
  console.log('modelFile.value ', modelFile.value, JSON.stringify(modelFile.value))
  try {
    await initSession();
  } catch (e) {
    sessionBackend.value = "wasm";
  }

  preLoadImageList()

  const uploadContainer = document.getElementById('upload-container-draggle')
  uploadContainer.addEventListener('dragover', (e) => {
    e.preventDefault()
    isUploadBg.value = true
  })

  uploadContainer.addEventListener('drop', (e) => {
    e.preventDefault()
    const fileList = [
      {
        originFileObj: e.dataTransfer.files[0]
      }
    ]
    handleReplaceBgChange({ fileList })
  })

  uploadContainer.addEventListener('dragenter', () => {
    isUploadBg.value = true
  })

  uploadContainer.addEventListener('dragleave', () => {
    isUploadBg.value = false
  })
});

const handleFileDrop = (e) => {
  uploadError.value = false;
  const file = e.dataTransfer.files[0]

  store.dispatch('closeSide', { isCollapse: true })
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  console.log("isJpgOrPng, isJpgOrPng");
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
  }

}

// 切换处理模式
const onSessionBackendChange = async (newVal) => {
  sessionBackend.value = newVal;
  clearCanvas();
  try {
    await initSession();
  } catch (e) {
    modelLoadingError.value = true;
  }
  return newVal;
};

watch(sessionBackend, (newVal) => {
  onSessionBackendChange(newVal);
});

// 文件上传
const changFileList = (fileList) => {
  clearRate.value = true
  if (!fileList || !fileList.length) {
    return;
  }
  if (resultImage.value) {
    changeImage.value = true;
  }
  clearCanvas();
  modelInProgress.value = 'start';
  rate1.value?.handleClearRate()
  rate.value?.handleClearRate()
  currentFileList.value = fileList;
  const file = fileList[0].originFileObj;
  imageHandleComplete.value = false;
  const url = URL.createObjectURL(file);
  loadImageToCanvas(url);
};

// 点击选择图片
const changSelectImage = (url) => {
  clearCanvas();
  modelInProgress.value = 'start';
  loadImageToCanvas(url);
};

// 上传之后canvas 绘制图片
const loadImageToCanvas = (url) => {
  if (!url) {
    rawImage.value = null;
    resultImage.value = null;
    return;
  }
  modelInProgress.value = 'progressing';
  loadImage(
    url,
    async (img) => {
      if (img.type === "error") {
        imageLoadingError.value = true;
        modelInProgress.value = 'done';
      } else {
        imageLoadingError.value = false;
        sessionRunning.value = true;
        inferenceTime.value = 0;
        const tempCtx = dataUtils.createCanvasCtx(img)
        tempCtx.drawImage(img, 0, 0)
        const imageData = tempCtx.getImageData(0, 0, img.width, img.height)
        rawImage.value = imageData;
        getClientHeight();
        modalData.value = {
          tempCtx,
          img,
        }
        // runModel(tempCtx, img);
      }
    },
    {
      cover: true,
      crop: true,
      canvas: true,
      crossOrigin: "Anonymous",
    }
  );
};

watchEffect(() => {
  if (session.value && modalData.value) {
    runModel(modalData.value.tempCtx, modalData.value.img);
  }
})

const runModel = async (tempCtx, img) => {
  sessionRunning.value = true;
  const { tensor, resizeSize, resizeData, imageData } = await props.preProcess(
    tempCtx,
    img,
  );

  let outputTensor;
  [outputTensor] = await runModelUtils.runModel(
    session.value,
    tensor
  );

  // 绘制抠图之后的图片
  props.postProcess(outputTensor, imageData, resizeData, resizeSize, img).then(async res => {
    // 缩放imageData
    const resizeImagedata = await dataUtils.resizeImageData(res, img.width, img.height)
    resultImage.value = resizeImagedata
    isModelDone.value = true
  });
  sessionRunning.value = false;
};

const drawDown = (value) => {
  if (value) {
    imageHandleComplete.value = true;
    modelInProgress.value = 'done';
    uploadPlaceholder.value = 'Change a portrait image here'
  }
}

// 背景替换
const handleReplaceBgChange = (value) => {
  console.log('handleReplaceBgChange', value)
  if (uploadError.value) {
    return;
  }
  bgFileList.value = value.fileList;
  const file = value.fileList[0].originFileObj;
  currentBgItem.value = file;
  //上报用户上传的背景图
  uploadBackground(file);
  showAddDesignResult.value = true;
  drawBgImage(file);
};

/** 获取指定链接下的位图图像 */
async function getLoadedImage (picFile) {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = (isString(picFile) ? picFile : URL.createObjectURL(picFile))
  await new Promise((resolve) => {
    img.onload = () => resolve();
  });
  const canvas = dataUtils.createCanvasCtx(img);
  canvas.drawImage(img, 0, 0);
  return canvas.getImageData(0, 0, img.width, img.height);
}

// 绘制背景图
const drawBgImage = async (file) => {
  if (!file) {
    return;
  }
  bgImage.value = await getLoadedImage(file);

}

// 背景上传
const customBgUpload = () => {
  console.log("上传背景");
};

// 选择背景
const handleSelectBg = async (item) => {
  addDesignPreview("toolkits_bgremove_add_design_preview", item.id)
  showAddDesignResult.value = true;
  currentBgItem.value = item;
  await drawBgImage(item.url, resultImage.value);
};


// 按钮的显示隐藏
const handleMattingBoxMouseEnter = () => {
  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

// 打开擦除弹窗
const handleOpenMattingModal = () => {
  isShowMattingModal.value = true;
};

// 关闭擦除弹窗
const handleMattingModalClose = () => {
  isShowMattingModal.value = false;
};

const handleSavaMattingImage = (imageData) => {
  resultImage.value = imageData;
};

// 打开背景替换弹窗
const handleOpenReplaceBgModal = () => {
  isShowReplaceBgModal.value = true;
};
// 关闭背景替换弹窗
const handleCloseReplaceBgModal = () => {
  isShowReplaceBgModal.value = false;
};

// 按钮显隐
const handleReplaceBgBoxMouseEnter = () => {
  showEditReplaceBgBtns.value = true;
};

const handleReplaceBgBoxMouseLeave = () => {
  showEditReplaceBgBtns.value = false;
};

const handleMattingChangeRate = (item) => {
  //上报emoji 
  postCanvasToURL(resultBoardRef.value.getImageCanvas(), "toolkits_bgremove_emoji", item)
  currentMattingSelectRate.value = item;
};

const handleReplaceBgChangeRate = (item) => {
  //上报emoji
  const params = {
    id: item.id,
    imageId: currentBgItem.value.id ? currentBgItem.value.id : "user_uploaded_images"
  }
  postCanvasToURL(bgBoardRef.value.getImageCanvas(), "toolkits_bgremove_add_design_emoji", params)
  currentReplaceBgSelectRate.value = item;
};


// 确认背景替换
const handleConfirmReplaceBg = (item) => {
  //上报替换背景
  let bgImageId = item.id ? item.id : "user_uploaded_images"
  postCanvasToURL(bgBoardRef.value.getImageCanvas(), "toolkits_bgremove_add_design_edit", bgImageId)
  showAddDesignResult.value = true;
  currentBgItem.value = item;
  const file = item.url ? item.url : item
  drawBgImage(file);
};

// 替换背景下载
const handleBgReplaceDownload = async () => {
  const imageData = resultImage.value;
  const canvas = document.createElement('canvas')
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  const bgBitmap = await createImageBitmap(bgImage.value);
  const resultBitmap = await createImageBitmap(resultImage.value);
  renderCoverBgImageInCanvas(bgBitmap, ctx);
  ctx.drawImage(resultBitmap, 0, 0);
  let bgImageId = currentBgItem.value.id ? currentBgItem.value.id : "user_uploaded_images"
  postCanvasToURL(canvas, "toolkits_bgremove_add_design_download", bgImageId);
  dataUtils.downloadFn(canvas, `add-background-${md5(Date.now())}`);
};

// 抠图下载
const handleMattingDownload = () => {
  const canvas = getCanvasFromImage(resultImage.value);
  postCanvasToURL(canvas, "toolkits_bgremove_download", {});
  dataUtils.downloadFn(canvas, `removed-background-${md5(Date.now())}`);
};

const getCanvasFromImage = (imageData) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  ctx.putImageData(imageData, 0, 0);
  return canvas;
}

// 清除画布
const clearCanvas = () => {
  inferenceTime.value = 0;
  imageURLInput.value = "";
  // modelInProgress.value = '';
  imageHandleComplete.value = false;
  imageLoadingError.value = false;
  showAddDesignResult.value = false;
  currentFileList.value = [];
  resultImage.value = null;
  rawImage.value = null;
  // dataUtils.clearCanvas('input-canvas')
  dataUtils.clearCanvas('output-canvas')
  dataUtils.clearCanvas('add-canvas')
};

const handleUploadBoxDrop = (e) => {
  console.log('handleUploadBoxDrop', e)
}

</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backGroundRemover-container {
  width: 100%;
  // height: 452px;

  display: flex;
  justify-content: center;

}

.container {
  margin-right: 30px;
}

.add-container {
  margin-right: 0;
}

.tool-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.result-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}

.position-box {
  position: relative;

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    font-family: 'Helvetica Neue';

    // animation: fadenum 0.5s 1;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  width: 412px;
  height: 412px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-panel {
  padding: 40px 20px;
  margin-top: 30px;
  background-color: white;
  position: relative;
}

.webcam-container {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  width: 412px;
  height: 412px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & :nth-child(n + 3) {
    position: absolute;
    font-size: 24px;

    & :first-child {
      background: white;
      color: black;
      opacity: 0.8;
      font-size: 12px;
      padding: 3px;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
}

.inputs {
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  width: 100%;
  height: 38px;
  font-size: 14px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  justify-content: center;
  padding: 0 16px;
}

.inputs:focus,
.inputs:hover {
  position: relative;
  background: rgba(0, 0, 0, 0.12);
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 245px;
  border-radius: 6px;
}

.add-design-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 79px 84px;
}

.add-design-container-hover {
  background-color: #ECECEC;

  :global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text-color) {
    background-color: #ECECEC;
  }

}

:global(.add-design-container .ant-upload.ant-upload-drag .ant-upload) {
  width: 245px;
  height: 64px;
  border: 1px dashed #878787;
  border-radius: 6px;
  background: #f7f7f7;
  padding: 0;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  text-align: center;
  color: #969696;
  cursor: pointer;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  height: 100%;
  font-size: 14px;
  line-height: 64px;
  color: #969696;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text:hover) {
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag) {
  border: none;
}

.add-design-tip {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 50px;

  P {
    margin-bottom: 0;
  }
}

.bg-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

:global(.unreal-dragger.ant-upload.ant-upload-drag) {
  width: 412px;
  height: 412px;
  border: none;
  background: none;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  margin: 0;
  border-radius: 6px;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  padding: 0;
  // background-color: rgba(135, 94, 255, 0.12);
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover) {
  background-color: rgba(135, 94, 255, 0.12);
}

:global(.upload-box .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #F7F7F7;
}
</style>
