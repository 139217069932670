var md5 = require("md5");
import CryptoJS from "crypto-js";

const AESKey = md5("console.log") + md5("document.querySelector");
const AESIv = md5("useEffect");

const AESDecData = (data, key, iv) => {
  // 这里的data是WordBuffer类型的数据
  const bKey = CryptoJS.enc.Hex.parse(key);
  // const bKey = CryptoJS.enc.Utf8.parse(key);
  const bIv = CryptoJS.enc.Hex.parse(iv);
  // const bIv = CryptoJS.enc.Utf8.parse(iv);
  const decrypt = CryptoJS.AES.decrypt({ ciphertext: data }, bKey, {
    iv: bIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const arrayBuffer = WordArrayToArrayBuffer(decrypt);
  return arrayBuffer;
};

const ArrayBufferToWordArray = (arrayBuffer) => {
  const u8 = new Uint8Array(arrayBuffer, 0, arrayBuffer.byteLength);
  const len = u8.length;
  const words = [];
  for (let i = 0; i < len; i += 1) {
    words[i >>> 2] |= (u8[i] & 0xff) << (24 - (i % 4) * 8);
  }
  return CryptoJS.lib.WordArray.create(words, len);
};

const WordArrayToArrayBuffer = (wordArray) => {
  const { words } = wordArray;
  const { sigBytes } = wordArray;
  const u8 = new Uint8Array(sigBytes);
  for (let i = 0; i < sigBytes; i += 1) {
    const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    u8[i] = byte;
  }
  return u8;
};

export default function dncData(arrayBuffer) {
  return new Promise((resolve, reject) => {
    var uint8View = new Uint8Array(arrayBuffer);
    const left = uint8View.slice(0, Math.ceil(uint8View.length / 2));
    const right = uint8View.slice(Math.ceil(uint8View.length / 2));
    var mergedArray = new Uint8Array(uint8View.length);
    mergedArray.set(right);
    mergedArray.set(left, right.length);
    const wordBuffer = ArrayBufferToWordArray(mergedArray.buffer);
    const decData = AESDecData(wordBuffer, AESKey, AESIv);
    resolve(decData.buffer);
  });
}
